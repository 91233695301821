import React from 'react';
import Loadable from 'react-loadable';
import { ComponentBuilder } from '@sitecore-jss/sitecore-jss-react';
const AboutUsAchievement = Loadable({
    loader: () => import(/* webpackChunkName: "AboutUsAchievement" */ '../components/AboutUsAchievement/AboutUsAchievement.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['AboutUsAchievement/AboutUsAchievement.js'],
  });
const AboutUsBanner = Loadable({
    loader: () => import(/* webpackChunkName: "AboutUsBanner" */ '../components/AboutUsBanner/AboutUsBanner.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['AboutUsBanner/AboutUsBanner.js'],
  });
const AnimatedSection = Loadable({
    loader: () => import(/* webpackChunkName: "AnimatedSection" */ '../components/animated-section/AnimatedSection.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['animated-section/AnimatedSection.js'],
  });
const AssetsCounter = Loadable({
    loader: () => import(/* webpackChunkName: "AssetsCounter" */ '../components/AssetsCounter/AssetsCounter.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['AssetsCounter/AssetsCounter.js'],
  });
const BlogCarousel = Loadable({
    loader: () => import(/* webpackChunkName: "BlogCarousel" */ '../components/blog-carousel/BlogCarousel.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['blog-carousel/BlogCarousel.js'],
  });
const BranchLocator = Loadable({
    loader: () => import(/* webpackChunkName: "BranchLocator" */ '../components/branchLocator/BranchLocator.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['branchLocator/BranchLocator.js'],
  });
const Breadcrumbs = Loadable({
    loader: () => import(/* webpackChunkName: "Breadcrumbs" */ '../components/Breadcrumbs/Breadcrumbs.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['Breadcrumbs/Breadcrumbs.js'],
  });
const BTCalculatorInWrapper = Loadable({
    loader: () => import(/* webpackChunkName: "BTCalculatorInWrapper" */ '../components/calculators/BTCalculatorInWrapper.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['calculators/BTCalculatorInWrapper.js'],
  });
const CalculatorWrapper = Loadable({
    loader: () => import(/* webpackChunkName: "CalculatorWrapper" */ '../components/calculators/CalculatorWrapper.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['calculators/CalculatorWrapper.js'],
  });
const FDInvestmentCalculator = Loadable({
    loader: () => import(/* webpackChunkName: "FDInvestmentCalculator" */ '../components/calculators/FDInvestmentCalculator.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['calculators/FDInvestmentCalculator.js'],
  });
const HLVCalculator = Loadable({
    loader: () => import(/* webpackChunkName: "HLVCalculator" */ '../components/calculators/HLVCalculator.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['calculators/HLVCalculator.js'],
  });
const HRACalculator = Loadable({
    loader: () => import(/* webpackChunkName: "HRACalculator" */ '../components/calculators/HRACalculator.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['calculators/HRACalculator.js'],
  });
const IncomeTaxCalculator = Loadable({
    loader: () => import(/* webpackChunkName: "IncomeTaxCalculator" */ '../components/calculators/IncomeTaxCalculator.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['calculators/IncomeTaxCalculator.js'],
  });
const InsuranceBMICalculator = Loadable({
    loader: () => import(/* webpackChunkName: "InsuranceBMICalculator" */ '../components/calculators/InsuranceBMICalculator.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['calculators/InsuranceBMICalculator.js'],
  });
const InvestmentMutualFundLumpsumCalculator = Loadable({
    loader: () => import(/* webpackChunkName: "InvestmentMutualFundLumpsumCalculator" */ '../components/calculators/InvestmentMutualFundLumpsumCalculator.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['calculators/InvestmentMutualFundLumpsumCalculator.js'],
  });
const InvestmentSIPCalculator = Loadable({
    loader: () => import(/* webpackChunkName: "InvestmentSIPCalculator" */ '../components/calculators/InvestmentSIPCalculator.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['calculators/InvestmentSIPCalculator.js'],
  });
const LoanHomeLoanEligibilityCalculator = Loadable({
    loader: () => import(/* webpackChunkName: "LoanHomeLoanEligibilityCalculator" */ '../components/calculators/LoanHomeLoanEligibilityCalculator.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['calculators/LoanHomeLoanEligibilityCalculator.js'],
  });
const LoanHomeLoanEmiCalculator = Loadable({
    loader: () => import(/* webpackChunkName: "LoanHomeLoanEmiCalculator" */ '../components/calculators/LoanHomeLoanEmiCalculator.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['calculators/LoanHomeLoanEmiCalculator.js'],
  });
const LoanPersonalLoanEligibilityCalculator = Loadable({
    loader: () => import(/* webpackChunkName: "LoanPersonalLoanEligibilityCalculator" */ '../components/calculators/LoanPersonalLoanEligibilityCalculator.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['calculators/LoanPersonalLoanEligibilityCalculator.js'],
  });
const LoanPersonalLoanEmiCalculator = Loadable({
    loader: () => import(/* webpackChunkName: "LoanPersonalLoanEmiCalculator" */ '../components/calculators/LoanPersonalLoanEmiCalculator.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['calculators/LoanPersonalLoanEmiCalculator.js'],
  });
const LoanPmayCalculator = Loadable({
    loader: () => import(/* webpackChunkName: "LoanPmayCalculator" */ '../components/calculators/LoanPmayCalculator.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['calculators/LoanPmayCalculator.js'],
  });
const PersonalFinanceCalcualtor = Loadable({
    loader: () => import(/* webpackChunkName: "PersonalFinanceCalcualtor" */ '../components/calculators/PersonalFinanceCalcualtor.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['calculators/PersonalFinanceCalcualtor.js'],
  });
const RentReceiptGenerator = Loadable({
    loader: () => import(/* webpackChunkName: "RentReceiptGenerator" */ '../components/calculators/RentReceiptGenerator/RentReceiptGenerator.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['calculators/RentReceiptGenerator/RentReceiptGenerator.js'],
  });
const RentReceiptPDFGenerator = Loadable({
    loader: () => import(/* webpackChunkName: "RentReceiptPDFGenerator" */ '../components/calculators/RentReceiptGenerator/RentReceiptPDFGenerator.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['calculators/RentReceiptGenerator/RentReceiptPDFGenerator.js'],
  });
const ABCDLeadForm = Loadable({
    loader: () => import(/* webpackChunkName: "ABCDLeadForm" */ '../components/carousel/ABCDLeadForm.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['carousel/ABCDLeadForm.js'],
  });
const BannerSlide = Loadable({
    loader: () => import(/* webpackChunkName: "BannerSlide" */ '../components/carousel/BannerSlide.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['carousel/BannerSlide.js'],
  });
const Carousel = Loadable({
    loader: () => import(/* webpackChunkName: "Carousel" */ '../components/carousel/Carousel.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['carousel/Carousel.js'],
  });
const CountdownTimer = Loadable({
    loader: () => import(/* webpackChunkName: "CountdownTimer" */ '../components/carousel/CountdownTimer.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['carousel/CountdownTimer.js'],
  });
const CountdownTimerSideMenu = Loadable({
    loader: () => import(/* webpackChunkName: "CountdownTimerSideMenu" */ '../components/carousel/CountdownTimerSideMenu.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['carousel/CountdownTimerSideMenu.js'],
  });
const PersonaliseMyProduct = Loadable({
    loader: () => import(/* webpackChunkName: "PersonaliseMyProduct" */ '../components/carousel/PersonaliseMyProduct.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['carousel/PersonaliseMyProduct.js'],
  });
const CategoryPaymentBanner = Loadable({
    loader: () => import(/* webpackChunkName: "CategoryPaymentBanner" */ '../components/CategoryPaymentBanner.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['CategoryPaymentBanner.js'],
  });
const CustomLink = Loadable({
    loader: () => import(/* webpackChunkName: "CustomLink" */ '../components/common/CustomLink.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['common/CustomLink.js'],
  });
const LinkProvision = Loadable({
    loader: () => import(/* webpackChunkName: "LinkProvision" */ '../components/common/LinkProvision.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['common/LinkProvision.js'],
  });
const CompanyProfileBanner = Loadable({
    loader: () => import(/* webpackChunkName: "CompanyProfileBanner" */ '../components/company-profile-banner/CompanyProfileBanner.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['company-profile-banner/CompanyProfileBanner.js'],
  });
const ConnectWithUsForm = Loadable({
    loader: () => import(/* webpackChunkName: "ConnectWithUsForm" */ '../components/connect-with-us-form/ConnectWithUsForm.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['connect-with-us-form/ConnectWithUsForm.js'],
  });
const ContentBlock = Loadable({
    loader: () => import(/* webpackChunkName: "ContentBlock" */ '../components/ContentBlock.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['ContentBlock.js'],
  });
const ActivityCard = Loadable({
    loader: () => import(/* webpackChunkName: "ActivityCard" */ '../components/csr-activities/ActivityCard.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['csr-activities/ActivityCard.js'],
  });
const CSRActivities = Loadable({
    loader: () => import(/* webpackChunkName: "CSRActivities" */ '../components/csr-activities/CSRActivities.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['csr-activities/CSRActivities.js'],
  });
const CTABanner = Loadable({
    loader: () => import(/* webpackChunkName: "CTABanner" */ '../components/cta-banner/CTABanner.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['cta-banner/CTABanner.js'],
  });
const DownloadAppBanner = Loadable({
    loader: () => import(/* webpackChunkName: "DownloadAppBanner" */ '../components/download-app-banner/DownloadAppBanner.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['download-app-banner/DownloadAppBanner.js'],
  });
const ExpertsOpinion = Loadable({
    loader: () => import(/* webpackChunkName: "ExpertsOpinion" */ '../components/experts-opinion/ExpertsOpinion.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['experts-opinion/ExpertsOpinion.js'],
  });
const TextSlide = Loadable({
    loader: () => import(/* webpackChunkName: "TextSlide" */ '../components/experts-opinion/TextSlide.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['experts-opinion/TextSlide.js'],
  });
const VideoSlide = Loadable({
    loader: () => import(/* webpackChunkName: "VideoSlide" */ '../components/experts-opinion/VideoSlide.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['experts-opinion/VideoSlide.js'],
  });
const ExploreOtherProducts = Loadable({
    loader: () => import(/* webpackChunkName: "ExploreOtherProducts" */ '../components/ExploreOtherProducts/ExploreOtherProducts.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['ExploreOtherProducts/ExploreOtherProducts.js'],
  });
const FAQs = Loadable({
    loader: () => import(/* webpackChunkName: "FAQs" */ '../components/faqs/FAQs.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['faqs/FAQs.js'],
  });
const FixedBottomDrawer = Loadable({
    loader: () => import(/* webpackChunkName: "FixedBottomDrawer" */ '../components/FixedBottomDrawer/FixedBottomDrawer.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['FixedBottomDrawer/FixedBottomDrawer.js'],
  });
const HeadingWithTabs = Loadable({
    loader: () => import(/* webpackChunkName: "HeadingWithTabs" */ '../components/HeadingWithTabs/HeadingWithTabs.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['HeadingWithTabs/HeadingWithTabs.js'],
  });
const JoinUs = Loadable({
    loader: () => import(/* webpackChunkName: "JoinUs" */ '../components/JoinUs/JoinUs.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['JoinUs/JoinUs.js'],
  });
const Leadership = Loadable({
    loader: () => import(/* webpackChunkName: "Leadership" */ '../components/leadership/Leadership.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['leadership/Leadership.js'],
  });
const LeaderDetail = Loadable({
    loader: () => import(/* webpackChunkName: "LeaderDetail" */ '../components/leadership-detail/LeaderDetail.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['leadership-detail/LeaderDetail.js'],
  });
const LeadershipTeamBanner = Loadable({
    loader: () => import(/* webpackChunkName: "LeadershipTeamBanner" */ '../components/LeadershipTeamBanner/LeadershipTeamBanner.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['LeadershipTeamBanner/LeadershipTeamBanner.js'],
  });
const MediaKit = Loadable({
    loader: () => import(/* webpackChunkName: "MediaKit" */ '../components/MediaKit/MediaKit.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['MediaKit/MediaKit.js'],
  });
const ABCDFooter = Loadable({
    loader: () => import(/* webpackChunkName: "ABCDFooter" */ '../components/navigation/ABCDFooter.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['navigation/ABCDFooter.js'],
  });
const ABCDFooterVariant = Loadable({
    loader: () => import(/* webpackChunkName: "ABCDFooterVariant" */ '../components/navigation/ABCDFooterVariant.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['navigation/ABCDFooterVariant.js'],
  });
const ABCHeader = Loadable({
    loader: () => import(/* webpackChunkName: "ABCHeader" */ '../components/navigation/ABCHeader.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['navigation/ABCHeader.js'],
  });
const DisclaimerMessage = Loadable({
    loader: () => import(/* webpackChunkName: "DisclaimerMessage" */ '../components/navigation/DisclaimerMessage.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['navigation/DisclaimerMessage.js'],
  });
const FinancialSolutions = Loadable({
    loader: () => import(/* webpackChunkName: "FinancialSolutions" */ '../components/navigation/FinancialSolutions.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['navigation/FinancialSolutions.js'],
  });
const MegaMenuHolder = Loadable({
    loader: () => import(/* webpackChunkName: "MegaMenuHolder" */ '../components/navigation/MegaMenuHolder.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['navigation/MegaMenuHolder.js'],
  });
const MegaMenuQuickServices = Loadable({
    loader: () => import(/* webpackChunkName: "MegaMenuQuickServices" */ '../components/navigation/MegaMenuQuickServices.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['navigation/MegaMenuQuickServices.js'],
  });
const MobileFinancialSolutions = Loadable({
    loader: () => import(/* webpackChunkName: "MobileFinancialSolutions" */ '../components/navigation/MobileFinancialSolutions.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['navigation/MobileFinancialSolutions.js'],
  });
const MobileNavigation = Loadable({
    loader: () => import(/* webpackChunkName: "MobileNavigation" */ '../components/navigation/MobileNavigation.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['navigation/MobileNavigation.js'],
  });
const SearchMenu = Loadable({
    loader: () => import(/* webpackChunkName: "SearchMenu" */ '../components/navigation/SearchMenu.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['navigation/SearchMenu.js'],
  });
const Tools = Loadable({
    loader: () => import(/* webpackChunkName: "Tools" */ '../components/navigation/Tools.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['navigation/Tools.js'],
  });
const TopNavigation = Loadable({
    loader: () => import(/* webpackChunkName: "TopNavigation" */ '../components/navigation/TopNavigation.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['navigation/TopNavigation.js'],
  });
const NeedMoreInfo = Loadable({
    loader: () => import(/* webpackChunkName: "NeedMoreInfo" */ '../components/NeedMoreInfo/NeedMoreInfo.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['NeedMoreInfo/NeedMoreInfo.js'],
  });
const OneVerseForm = Loadable({
    loader: () => import(/* webpackChunkName: "OneVerseForm" */ '../components/one-verse-form/OneVerseForm.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['one-verse-form/OneVerseForm.js'],
  });
const OurSubsidiaries = Loadable({
    loader: () => import(/* webpackChunkName: "OurSubsidiaries" */ '../components/OurSubsidiaries/OurSubsidiaries.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['OurSubsidiaries/OurSubsidiaries.js'],
  });
const ProductBanner = Loadable({
    loader: () => import(/* webpackChunkName: "ProductBanner" */ '../components/ProductBanner/ProductBanner.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['ProductBanner/ProductBanner.js'],
  });
const ScrollTop = Loadable({
    loader: () => import(/* webpackChunkName: "ScrollTop" */ '../components/scroll-top/ScrollTop.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['scroll-top/ScrollTop.js'],
  });
const SideMenu = Loadable({
    loader: () => import(/* webpackChunkName: "SideMenu" */ '../components/side-menu/SideMenu.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['side-menu/SideMenu.js'],
  });
const SideMenuDownloadApp = Loadable({
    loader: () => import(/* webpackChunkName: "SideMenuDownloadApp" */ '../components/side-menu/SideMenuDownloadApp.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['side-menu/SideMenuDownloadApp.js'],
  });
const SideMenuOurCompanies = Loadable({
    loader: () => import(/* webpackChunkName: "SideMenuOurCompanies" */ '../components/side-menu/SideMenuOurCompanies.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['side-menu/SideMenuOurCompanies.js'],
  });
const StartJourneyForm = Loadable({
    loader: () => import(/* webpackChunkName: "StartJourneyForm" */ '../components/start-journey-form/StartJourneyForm.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['start-journey-form/StartJourneyForm.js'],
  });
const StickyNav = Loadable({
    loader: () => import(/* webpackChunkName: "StickyNav" */ '../components/sticky-nav/StickyNav.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['sticky-nav/StickyNav.js'],
  });
const Tabular = Loadable({
    loader: () => import(/* webpackChunkName: "Tabular" */ '../components/Tabular/Tabular.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['Tabular/Tabular.js'],
  });
const TeamInAction = Loadable({
    loader: () => import(/* webpackChunkName: "TeamInAction" */ '../components/TeamInAction/TeamInAction.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['TeamInAction/TeamInAction.js'],
  });
const reactNouislider = Loadable({
    loader: () => import(/* webpackChunkName: "reactNouislider" */ '../components/third-party/react-nouislider.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['third-party/react-nouislider.js'],
  });
const BottomTicker = Loadable({
    loader: () => import(/* webpackChunkName: "BottomTicker" */ '../components/ticker/BottomTicker.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['ticker/BottomTicker.js'],
  });
const Countdown = Loadable({
    loader: () => import(/* webpackChunkName: "Countdown" */ '../components/ticker/Countdown.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['ticker/Countdown.js'],
  });
const Ticker = Loadable({
    loader: () => import(/* webpackChunkName: "Ticker" */ '../components/ticker/Ticker.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['ticker/Ticker.js'],
  });
const TimeLineSteps = Loadable({
    loader: () => import(/* webpackChunkName: "TimeLineSteps" */ '../components/timeline-steps/TimeLineSteps.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['timeline-steps/TimeLineSteps.js'],
  });
const TypeWriterText = Loadable({
    loader: () => import(/* webpackChunkName: "TypeWriterText" */ '../components/TypeWriterText/TypeWriterText.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['TypeWriterText/TypeWriterText.js'],
  });
const UnderstandLifeInsurance = Loadable({
    loader: () => import(/* webpackChunkName: "UnderstandLifeInsurance" */ '../components/understand-life-insurance/UnderstandLifeInsurance.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['understand-life-insurance/UnderstandLifeInsurance.js'],
  });
const VisionValues = Loadable({
    loader: () => import(/* webpackChunkName: "VisionValues" */ '../components/VisionValues/VisionValues.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['VisionValues/VisionValues.js'],
  });

const components = new Map();
components.set('AboutUsAchievement', AboutUsAchievement);
components.set('AboutUsBanner', AboutUsBanner);
components.set('AnimatedSection', AnimatedSection);
components.set('AssetsCounter', AssetsCounter);
components.set('BlogCarousel', BlogCarousel);
components.set('BranchLocator', BranchLocator);
components.set('Breadcrumbs', Breadcrumbs);
components.set('BTCalculatorInWrapper', BTCalculatorInWrapper);
components.set('CalculatorWrapper', CalculatorWrapper);
components.set('FDInvestmentCalculator', FDInvestmentCalculator);
components.set('HLVCalculator', HLVCalculator);
components.set('HRACalculator', HRACalculator);
components.set('IncomeTaxCalculator', IncomeTaxCalculator);
components.set('InsuranceBMICalculator', InsuranceBMICalculator);
components.set('InvestmentMutualFundLumpsumCalculator', InvestmentMutualFundLumpsumCalculator);
components.set('InvestmentSIPCalculator', InvestmentSIPCalculator);
components.set('LoanHomeLoanEligibilityCalculator', LoanHomeLoanEligibilityCalculator);
components.set('LoanHomeLoanEmiCalculator', LoanHomeLoanEmiCalculator);
components.set('LoanPersonalLoanEligibilityCalculator', LoanPersonalLoanEligibilityCalculator);
components.set('LoanPersonalLoanEmiCalculator', LoanPersonalLoanEmiCalculator);
components.set('LoanPmayCalculator', LoanPmayCalculator);
components.set('PersonalFinanceCalcualtor', PersonalFinanceCalcualtor);
components.set('RentReceiptGenerator', RentReceiptGenerator);
components.set('RentReceiptPDFGenerator', RentReceiptPDFGenerator);
components.set('ABCDLeadForm', ABCDLeadForm);
components.set('BannerSlide', BannerSlide);
components.set('Carousel', Carousel);
components.set('CountdownTimer', CountdownTimer);
components.set('CountdownTimerSideMenu', CountdownTimerSideMenu);
components.set('PersonaliseMyProduct', PersonaliseMyProduct);
components.set('CategoryPaymentBanner', CategoryPaymentBanner);
components.set('CustomLink', CustomLink);
components.set('LinkProvision', LinkProvision);
components.set('CompanyProfileBanner', CompanyProfileBanner);
components.set('ConnectWithUsForm', ConnectWithUsForm);
components.set('ContentBlock', ContentBlock);
components.set('ActivityCard', ActivityCard);
components.set('CSRActivities', CSRActivities);
components.set('CTABanner', CTABanner);
components.set('DownloadAppBanner', DownloadAppBanner);
components.set('ExpertsOpinion', ExpertsOpinion);
components.set('TextSlide', TextSlide);
components.set('VideoSlide', VideoSlide);
components.set('ExploreOtherProducts', ExploreOtherProducts);
components.set('FAQs', FAQs);
components.set('FixedBottomDrawer', FixedBottomDrawer);
components.set('HeadingWithTabs', HeadingWithTabs);
components.set('JoinUs', JoinUs);
components.set('Leadership', Leadership);
components.set('LeaderDetail', LeaderDetail);
components.set('LeadershipTeamBanner', LeadershipTeamBanner);
components.set('MediaKit', MediaKit);
components.set('ABCDFooter', ABCDFooter);
components.set('ABCDFooterVariant', ABCDFooterVariant);
components.set('ABCHeader', ABCHeader);
components.set('DisclaimerMessage', DisclaimerMessage);
components.set('FinancialSolutions', FinancialSolutions);
components.set('MegaMenuHolder', MegaMenuHolder);
components.set('MegaMenuQuickServices', MegaMenuQuickServices);
components.set('MobileFinancialSolutions', MobileFinancialSolutions);
components.set('MobileNavigation', MobileNavigation);
components.set('SearchMenu', SearchMenu);
components.set('Tools', Tools);
components.set('TopNavigation', TopNavigation);
components.set('NeedMoreInfo', NeedMoreInfo);
components.set('OneVerseForm', OneVerseForm);
components.set('OurSubsidiaries', OurSubsidiaries);
components.set('ProductBanner', ProductBanner);
components.set('ScrollTop', ScrollTop);
components.set('SideMenu', SideMenu);
components.set('SideMenuDownloadApp', SideMenuDownloadApp);
components.set('SideMenuOurCompanies', SideMenuOurCompanies);
components.set('StartJourneyForm', StartJourneyForm);
components.set('StickyNav', StickyNav);
components.set('Tabular', Tabular);
components.set('TeamInAction', TeamInAction);
components.set('react-nouislider', reactNouislider);
components.set('BottomTicker', BottomTicker);
components.set('Countdown', Countdown);
components.set('Ticker', Ticker);
components.set('TimeLineSteps', TimeLineSteps);
components.set('TypeWriterText', TypeWriterText);
components.set('UnderstandLifeInsurance', UnderstandLifeInsurance);
components.set('VisionValues', VisionValues);

const componentBuilder = new ComponentBuilder({ components });

export const componentFactory = componentBuilder.getComponentFactory();
