import React, { useEffect, useState } from 'react';
import { Image, RichText, Text } from '@sitecore-jss/sitecore-jss-react';
import { handleMainNavCTAClick, handleArticleClick, handleMainNavClick } from '../../lib/customGA4';
import CustomLink from '../common/CustomLink';

const MegaMenuHolder = (props) => {
  const [activeSecondaryTab, setActiveSecondaryTab] = useState('');
  const [initialRender, setInitialRender] = useState(true);
  const [activeCrossSell, setActiveCrossSell] = useState([]);
  const [selectedSecondaryTab, setSelectedSecondaryTab] = useState('');
  const { activeTab, clickedTab, navigationResult } = props;
  const [activeThirdTab, setActiveThirdTab] = useState('');

  const changeActiveSecondaryTab = (newTab, TabName) => {
    setSelectedSecondaryTab(TabName);
    if (initialRender) {
      setInitialRender(false);
    }
    setActiveThirdTab('');
    setActiveSecondaryTab(newTab);
    const crossSellData =
      navigationResult?.primary
        .find((mainNav) => mainNav.tabs.some((tab) => tab.id === newTab))
        ?.tabs.find((tab) => tab.id === newTab)?.crossSell || [];
    setActiveCrossSell(crossSellData);
  };

  const changeActiveThirdTab = (newTab) => {
    setActiveThirdTab(newTab);
  };

  useEffect(() => {
    if (typeof window !== 'undefined' && typeof document !== 'undefined') {
      const initializeSwiper = () => {
        const swiper1 = new Swiper('.nav_related_reads_slider', {
          slidesPerView: 1,
          centeredSlides: true,
          spaceBetween: 0,
          pagination: {
            el: '.swiper-pagination',
            clickable: true,
          },
          autoplay: {
            delay: 5000,
            disableOnInteraction: false,
          },
        });

        const swiper2 = new Swiper('.sip_calc_nav_slider', {
          slidesPerView: 1,
          centeredSlides: true,
          spaceBetween: 0,
          pagination: {
            el: '.swiper-pagination',
            clickable: true,
          },
          autoplay: {
            delay: 5000,
            disableOnInteraction: false,
          },
        });
      };

      if (document.readyState === 'complete') {
        // Document is already loaded, initialize Swiper immediately
        initializeSwiper();
      } else {
        // Document is not yet loaded, set up an event listener for the load event
        window.addEventListener('load', initializeSwiper);
        // Cleanup event listener on component unmount
        return () => {
          window.removeEventListener('load', initializeSwiper);
        };
      }
    }
  }, []);

  useEffect(() => {
    if (navigationResult?.primary) {
      const initialTab = navigationResult.primary.find((mainNav) => mainNav.id === activeTab)
        ?.tabs[0];
      if (initialTab) {
        setActiveSecondaryTab(initialTab.id);
        setSelectedSecondaryTab(initialTab.menuTitle.value);
        setActiveCrossSell(initialTab.crossSell || []);
      }
    }
  }, [activeTab, navigationResult?.primary]);

  return (
    <>
      {navigationResult?.primary?.map((mainNav) => {
        const transformedTab = clickedTab?.toLowerCase().replace(/\s+/g, '_');
        return (
          <div
            className={`${
              transformedTab === 'about_us'
                ? 'about_us_megamenu_wrapper'
                : `${mainNav?.CssClassName?.value}_megamenu_wrapper`
            } ${props?.navStickyClass} ${activeTab === mainNav.id ? 'megamenu_open' : ''}`}
            key={mainNav.id}
            id={`${mainNav.CssClassName.value}_wrapper_id`}
          >
            <div className={`${mainNav?.CssClassName?.value}_megamenu_container`}>
              <div className={`${mainNav?.CssClassName?.value}_options_wrapper`}>
                <div className={`${mainNav?.CssClassName?.value}_lists_wrapper`}>
                  <ul className={`${mainNav?.CssClassName?.value}_lists`}>
                    {mainNav?.tabs?.map((tab, index) => {
                      const isFirstTab = initialRender && index === 0;
                      const isFirstTabActive = isFirstTab || activeSecondaryTab === tab?.id;
                      return (
                        <li
                          key={tab?.id}
                          id={tab?.CssId.value}
                          className={`${isFirstTabActive ? 'list_hovered' : ''}`}
                          onMouseEnter={() => {
                            changeActiveSecondaryTab(tab?.id, tab?.menuTitle?.value);
                          }}
                        >
                          {tab?.menuLink?.url === null ||
                          tab?.menuLink?.url === 'http://javascript:void(0)' ? (
                            <a id="menuTitle">
                              <Text field={tab?.menuTitle} editable />
                              <em className="right_arrow"></em>
                            </a>
                          ) : (
                            <a
                              id="menuTitle"
                              href={tab?.menuLink?.url}
                              onClick={() =>
                                handleMainNavClick(clickedTab, null, tab?.menuTitle?.value)
                              }
                            >
                              <Text field={tab?.menuTitle} editable />
                              <em className="right_arrow"></em>
                            </a>
                          )}
                        </li>
                      );
                    })}
                  </ul>
                  {activeCrossSell?.length > 0 && (
                    <div className={`${mainNav?.CssClassName?.value}_info`}>
                      <span className="heading-mfy">
                        <RichText field={activeCrossSell[0]?.heading} editable />
                        <RichText field={activeCrossSell[0]?.subheading} editable />
                      </span>

                      <div className="know-more-btn nav_knw_btn_wrapper">
                        {activeCrossSell[0]?.knowMoreButton?.url && (
                          <a
                            className="know_more_about-us outline_btn"
                            href={activeCrossSell[0]?.knowMoreButton?.url}
                            target={activeCrossSell[0]?.knowMoreButton?.target}
                          >
                            <Text field={activeCrossSell[0]?.knowMoreLabel} editable />
                          </a>
                        )}
                        {activeCrossSell[0]?.applyNowButton?.url && (
                          <a
                            className="know_more_about-us solid_btn"
                            href={activeCrossSell[0]?.applyNowButton?.url}
                            target={activeCrossSell[0]?.applyNowButton?.target}
                          >
                            <Text field={activeCrossSell[0]?.applyNowLabel} editable />
                          </a>
                        )}
                      </div>
                    </div>
                  )}
                </div>

                {mainNav?.tabs.map((tab, index) => {
                  const transformedTab = clickedTab?.toLowerCase().replace(/\s+/g, '_');
                  const isAboutUs = transformedTab === 'about_us';
                  const abflClass =
                    tab?.quickLinksFolder_abfl?.length > 0
                      ? isAboutUs
                        ? index === 0
                          ? 'abfl_col_2'
                          : 'abfl_col_3'
                        : 'abfl_col_2'
                      : '';
                  const quickLinks = tab?.quickLinksFolder || [];
                  const freeCredit = tab?.free_credit_score || [];
                  const isQuickFirst = ['true', '1'].includes(
                    quickLinks[0]?.isFirst?.value?.toLowerCase()
                  );
                  const isFreeFirst = ['true', '1'].includes(
                    freeCredit[0]?.isFirst?.value?.toLowerCase()
                  );
                  const targetItems = [...quickLinks, ...freeCredit];
                  const MenuBottomContent = {
                    targetItems: isFreeFirst && !isQuickFirst ? targetItems.reverse() : targetItems,
                  };
                  return (
                    <div
                      className={`${mainNav.CssClassName?.value}_plans_Wrapper ${
                        activeSecondaryTab === tab.id ||
                        (activeSecondaryTab === '' && mainNav?.tabs[0].id === tab.id)
                          ? 'list_hovered'
                          : 'd-none'
                      } ${abflClass}`}
                      key={index}
                    >
                      <div
                        className={`terms_list_wrapper ${
                          tab?.quickLinksFolder_abfl?.length > 0 ? 'abfl_grid_Wrapper' : ''
                        }`}
                      >
                        {tab?.secondary.length > 0 && (
                          <div className="terms_list_grid">
                            {tab?.secondary?.map((menuItem, index) => {
                              return (
                                <a
                                  className="terms_wrapper"
                                  key={index}
                                  href={menuItem?.menuLink?.url}
                                  target={menuItem?.menuLink?.target}
                                  onClick={() =>
                                    handleMainNavClick(
                                      clickedTab,
                                      selectedSecondaryTab,
                                      menuItem?.menuTitle?.value
                                    )
                                  }
                                >
                                  <Image
                                    field={menuItem?.icon_03d0a54087195a4da66fc707ac0c4572}
                                    loading="lazy"
                                    editable
                                    alt="img"
                                  />
                                  <span className="term-card-heading">
                                    <Text field={menuItem?.menuTitle} editable />
                                  </span>
                                  <Text field={menuItem?.menuSubTitle} tag="p" editable />
                                </a>
                              );
                            })}

                            <div
                              className={`quick_links_wrapper" ${
                                Array.isArray(tab?.quickLinksFolder) &&
                                tab.quickLinksFolder.length > 0
                                  ? 'block'
                                  : 'hide'
                              }`}
                            >
                              <span className="quick-link-heading">
                                <Text field={tab?.quickLinksFolder?.heading} editable />
                              </span>
                              <div className="wrapper-row">
                                {tab?.quickLinksFolder?.quickLinks?.map((quickLink) => {
                                  return (
                                    <div className="row-col" key={quickLink?.label?.value}>
                                      <div className="quick_links_grid">
                                        <ul>
                                          <li>
                                            <a
                                              href={quickLink?.link?.url}
                                              target={quickLink?.link?.target}
                                            >
                                              {quickLink?.label?.value}{' '}
                                              <em className="chevron_right"></em>
                                            </a>
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          </div>
                        )}

                        {tab?.menuTabs.length > 0 && (
                          <>
                            {tab?.menuTabs.map((menu, index) => (
                              <span key={index}>
                                <span className="wrapper_title_text">
                                  <Text field={menu?.menuTitle} editable />
                                </span>
                                <div className="terms_list_grid">
                                  {menu?.secondary?.map((menuItem, index) => {
                                    return (
                                      <a
                                        className="terms_wrapper"
                                        key={index}
                                        href={menuItem?.menuLink?.url}
                                        target={menuItem?.menuLink?.target}
                                      >
                                        <Image
                                          field={menuItem?.icon_03d0a54087195a4da66fc707ac0c4572}
                                          loading="lazy"
                                          editable
                                          alt="img"
                                        />
                                        <span className="term-card-heading">
                                          <Text field={menuItem?.menuTitle} editable />
                                        </span>
                                        <p>
                                          <Text field={menuItem?.menuSubTitle} editable />
                                        </p>
                                      </a>
                                    );
                                  })}
                                </div>{' '}
                              </span>
                            ))}
                          </>
                        )}

                        {tab?.quickLinksFolder_abfl?.length > 0 && (
                          <>
                            {tab?.quickLinksFolder_abfl[0]?.step1
                              ?.reduce((result, step, index) => {
                                if (index % 2 === 0) {
                                  result.push([step]);
                                } else {
                                  result[result.length - 1].push(step);
                                }
                                return result;
                              }, [])
                              .map((group, groupIndex) => (
                                <div className="all_solutions_url_col" key={groupIndex}>
                                  <span
                                    className={`url_col_head ${
                                      groupIndex !== 0 ? 'hidden-heading' : ''
                                    }`}
                                  >
                                    <Text field={tab?.quickLinksFolder_abfl[0]?.heading} />
                                  </span>
                                  {group.map((step, stepIndex) => (
                                    <React.Fragment key={stepIndex}>
                                      {step?.heading?.value && (
                                        <p className="url_head_nav">
                                          <Text field={step?.heading} editable />
                                        </p>
                                      )}
                                      <ul className="url_list_ul">
                                        {step.quickLinks.map((link, linkIndex) => (
                                          <li key={linkIndex}>
                                            <a href={link?.link?.url} target={link?.link?.target}>
                                              {link?.label?.value}
                                            </a>
                                          </li>
                                        ))}
                                      </ul>
                                    </React.Fragment>
                                  ))}
                                </div>
                              ))}
                          </>
                        )}

                        {MenuBottomContent?.targetItems.length > 0 && (
                          <div
                            className={`quick_links_wrapper ${
                              MenuBottomContent?.targetItems.length === 1 &&
                              MenuBottomContent?.targetItems[0]?.quickLinks
                                ? 'quick_links_wrapper-f2'
                                : ''
                            }`}
                          >
                            <div className="wrapper-row">
                              {MenuBottomContent?.targetItems.map((item, index) => (
                                <React.Fragment key={index}>
                                  {/* Render quick links if they exist */}
                                  {item?.quickLinks?.length > 0 && (
                                    <div className="row-col">
                                      <div className="quick_links_grid block">
                                        {item.heading.value && (
                                          <span className="quick-link-heading">
                                            <Text field={item.heading} editable />
                                          </span>
                                        )}
                                        <ul>
                                          {item.quickLinks.map((quickLink, idx) => (
                                            <li key={idx}>
                                              <a
                                                href={quickLink?.link?.url}
                                                target={quickLink?.link?.target}
                                              >
                                                <Text field={quickLink?.label} editable />
                                                <em className="chevron_right"></em>
                                              </a>
                                            </li>
                                          ))}
                                        </ul>
                                      </div>
                                    </div>
                                  )}

                                  {/* Render the free credit score section if it exists */}
                                  {item?.link && (
                                    <div className="recoll">
                                      <div className="fcs-vs">
                                        <div
                                          className="bmi_cal_wrapper"
                                          style={{
                                            background: `url(${item?.backgroundImage?.src})`,
                                            backgroundSize: 'cover',
                                          }}
                                        >
                                          <div className="bmi_ico">
                                            <Image field={item?.calculatorIcon} loading="lazy" />
                                          </div>
                                          <div className="bmi_content">
                                            <div className="quick-link-heading">
                                              <Text field={item?.heading} editable />
                                            </div>
                                            <p>
                                              <Text field={item?.subheading} editable />
                                            </p>
                                            <a href={item?.link?.url} target={item?.link?.target}>
                                              {item?.linkLabel?.value}
                                            </a>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </React.Fragment>
                              ))}
                            </div>
                          </div>
                        )}

                        {tab?.menuBanner?.length > 0 && (
                          <div className="open_demat_account_main_wrapper">
                            <div className="open_demat_account_wrapper">
                              <Image
                                field={tab?.menuBanner[0]?.BackgroundImage}
                                loading="lazy"
                                editable
                                alt="img"
                              />
                              <div className="open_demat_grid">
                                <div className="open_demat_left_col">
                                  {/* <img src="https://abconeprod.azureedge.net/dist/ABCCorp/assets/images/nav_open_demat_account.png" /> */}
                                  <Image
                                    field={tab?.menuBanner[0]?.LeftSideImage}
                                    loading="lazy"
                                    editable
                                    alt="img"
                                  />
                                </div>
                                <div className="open_demat_right_col">
                                  <Text tag="span" field={tab?.menuBanner[0]?.Heading} />
                                  <Text tag="p" field={tab?.menuBanner[0]?.Subheading} />
                                  <RichText field={tab?.menuBanner[0]?.BulletPoints} />
                                  <a
                                    href={tab?.menuBanner[0]?.Link?.url}
                                    className="open_demat_btn"
                                  >
                                    {tab?.menuBanner[0]?.Link?.text}
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>

                      <div
                        className={
                          tab?.quickLinksFolder_abfl[1]?.step1[0]?.quickLinks?.length > 0
                            ? ''
                            : 'bmi_calc_related_reads'
                        }
                      >
                        {tab?.calculatorLinks[0]?.children.length > 0 && (
                          <div className="bmi_cal_slider_wrapper">
                            <div className="swiper sip_calc_nav_slider">
                              <div className="swiper-wrapper">
                                {tab?.calculatorLinks[0]?.children.map((calc, index) => {
                                  return (
                                    <div
                                      key={index}
                                      className="swiper-slide swiper-slide-active"
                                      role="group"
                                      aria-label={`${index + 1} / 3`}
                                      style={{
                                        background: `url(${calc?.backgroundImage?.src})`,
                                        backgroundSize: 'cover',
                                        width: '275px',
                                        marginRight: '10px',
                                      }}
                                    >
                                      <div className="bmi_cal_wrapper">
                                        <div className="bmi_ico">
                                          <Image field={calc?.calculatorIcon} loading="lazy" />
                                        </div>
                                        <div className="bmi_content">
                                          <span className="bmi_content_heading">
                                            {calc?.heading?.value}
                                          </span>
                                          <p>{calc?.subheading?.value}</p>
                                          <a
                                            href={calc?.link?.url}
                                            target={calc?.link?.target}
                                            onClick={() =>
                                              handleMainNavCTAClick(
                                                clickedTab,
                                                calc?.heading?.value,
                                                calc?.linkLabel?.value
                                              )
                                            }
                                          >
                                            {calc?.linkLabel?.value}
                                          </a>
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })}
                              </div>
                              <div className="swiper-pagination sip_calc_pagination"></div>
                            </div>
                          </div>
                        )}
                        {tab?.relatedReadsFolder.length > 0 && (
                          <div className="related_reads_wrapper">
                            <span className="related_reads_heading">
                              <Text field={tab?.relatedReadsFolder[0]?.heading} editable />
                            </span>
                            <div className="swiper nav_related_reads_slider nav_slider_Wrapper swiper-initialized swiper-horizontal swiper-backface-hidden ">
                              <div className="swiper-wrapper">
                                {tab?.relatedReadsFolder[0]?.relatedReads?.map((relatedRead) => {
                                  return (
                                    <div key={relatedRead?.id} className="swiper-slide">
                                      <div className="related_reads_slide_wrapper ">
                                        <picture>
                                          <source
                                            srcSet={relatedRead?.bgImage?.src}
                                            type="image/webp"
                                          />
                                          <Image
                                            field={relatedRead?.bgImage}
                                            className="w-100"
                                            loading="lazy"
                                            alt="img"
                                          />
                                        </picture>
                                        <div className="nav_slider_content">
                                          <a
                                            href={relatedRead?.ctaLink?.url}
                                            target={relatedRead?.ctaLink?.target}
                                            onClick={() =>
                                              handleArticleClick(
                                                relatedRead?.heading?.value,
                                                clickedTab
                                              )
                                            }
                                          >
                                            <span className="nav-slide-heading">
                                              {relatedRead?.heading?.value}
                                            </span>
                                          </a>
                                          <p>{relatedRead?.subHeading?.value}</p>
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })}
                              </div>
                              <div className="swiper-pagination nav_related_reads_pagination"></div>
                            </div>
                          </div>
                        )}
                        {tab?.quickLinksFolder_abfl[1]?.step1[0]?.quickLinks?.length > 0 && (
                          <div className="terms_list_wrapper">
                            <div className="all_solutions_url_col">
                              <span className="url_col_head nav_mt16">
                                <Text field={tab?.quickLinksFolder_abfl[1]?.heading} editable />
                              </span>
                              <ul className="url_list_ul">
                                {tab?.quickLinksFolder_abfl[1]?.step1[0]?.quickLinks?.map(
                                  (link, index) => (
                                    <li key={index}>
                                      <a href={link?.link?.url} target={link?.link?.target}>
                                        {link?.label?.value}
                                      </a>
                                    </li>
                                  )
                                )}
                              </ul>
                            </div>
                          </div>
                        )}
                        {tab?.relatedReads_abfl?.length > 0 && (
                          <>
                            <div className="abfl_top_nav_wrapper">
                              <img src={tab?.relatedReads_abfl[0]?.image?.src} />
                              <span>
                                <Text field={tab?.relatedReads_abfl[0]?.heading} editable />
                              </span>
                              <p>
                                <Text field={tab?.relatedReads_abfl[0]?.subHeading} editable />
                              </p>
                            </div>
                            <RichText
                              className="abcl_nav_stats"
                              field={tab?.relatedReads_abfl[0]?.richTextContent}
                              editable
                            />
                          </>
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        );
      })}
    </>
  );
};

export default MegaMenuHolder;
