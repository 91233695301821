import React from 'react';
import PropTypes from 'prop-types';

const CustomLink = React.forwardRef(function CustomLink(
  {
    field,
    className,
    editable = true,
    showLinkTextWithChildrenPresent = false,
    dataAccordion,
    dataTab,
    dataPlan,
    dataPosition,
    dataRedirectURL,
    dataRedirectProductCode,
    dataRedirectProductURL,
    dataSimpleRedirection,
    handleClick,
    img,
    ...props
  },
  ref
) {
  const fieldValue = field?.value || field;
  const hrefParts = fieldValue?.href?.split(' ') || fieldValue?.url?.split(' ');
  const dataTarget = hrefParts?.find((part) => part.startsWith('#'));
  const dataOneverse = hrefParts
    ?.find((part) => part.includes('data-oneverse'))
    ?.split('=')[1]
    ?.replace(/"/g, '');
  const dataUsp = hrefParts
    ?.find((part) => part.includes('data-usp'))
    ?.split('=')[1]
    ?.replace(/"/g, '');
  const dataDropdown = hrefParts
    ?.find((part) => part.includes('data-dropdown'))
    ?.split('=')[1]
    ?.replace(/"/g, '');

  const isAnchorLink = fieldValue.linkType === 'anchor' || fieldValue.linktype === 'anchor';

  return isAnchorLink && fieldValue.text ? (
    <a
      className={fieldValue.className || fieldValue.class || className}
      data-bs-toggle={dataTarget ? 'modal' : undefined}
      data-bs-target={dataTarget}
      data-oneverse={dataOneverse || undefined}
      data-usp={dataUsp || undefined}
      data-dropdown={dataDropdown || undefined}
      data-accordion={dataAccordion || undefined}
      data-tab={dataTab || undefined}
      data-plan={dataPlan || undefined}
      data-position={dataPosition || undefined}
      data-redirect-url={dataRedirectURL || undefined}
      data-redirect-product-code={dataRedirectProductCode || undefined}
      data-redirect-product-url={dataRedirectProductURL || undefined}
      data-simple-redirection={dataSimpleRedirection || undefined}
      href="#"
      onClick={handleClick}
      ref={ref}
      {...props}
    >
      {img && img}
      {fieldValue.text}
    </a>
  ) : (
    (fieldValue.text || fieldValue.href || fieldValue.url) && (
      <a
        href={fieldValue.href || fieldValue.url || undefined}
        className={fieldValue.className || fieldValue.class || className}
        title={fieldValue.title || undefined}
        target={fieldValue.target || undefined}
        onClick={handleClick}
        ref={ref}
        {...props}
      >
        {img && img}
        {props.children || fieldValue.text || (editable && (fieldValue.href || fieldValue.url))}
      </a>
    )
  );
});

CustomLink.propTypes = {
  field: PropTypes.oneOfType([
    PropTypes.shape({
      href: PropTypes.string,
      className: PropTypes.string,
      class: PropTypes.string,
      title: PropTypes.string,
      target: PropTypes.string,
      text: PropTypes.string,
      anchor: PropTypes.string,
      querystring: PropTypes.string,
      linktype: PropTypes.string,
    }),
    PropTypes.shape({
      value: PropTypes.shape({
        href: PropTypes.string,
        className: PropTypes.string,
        class: PropTypes.string,
        title: PropTypes.string,
        target: PropTypes.string,
        text: PropTypes.string,
        anchor: PropTypes.string,
        querystring: PropTypes.string,
        linktype: PropTypes.string,
      }),
      editableFirstPart: PropTypes.string,
      editableLastPart: PropTypes.string,
    }),
  ]).isRequired,
  editable: PropTypes.bool,
  showLinkTextWithChildrenPresent: PropTypes.bool,
  className: PropTypes.string,
  linkType: PropTypes.string,
  href: PropTypes.string,
  dataAccordion: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  dataTab: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  dataPlan: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  dataPosition: PropTypes.string,
  dataRedirectURL: PropTypes.string,
  dataRedirectProductCode: PropTypes.string,
  dataRedirectProductURL: PropTypes.string,
  dataSimpleRedirection: PropTypes.string,
  handleClick: PropTypes.func,
  text: PropTypes.string,
  img: PropTypes.node,
  target: PropTypes.string,
};

export default CustomLink;
